import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "bluejay-ui";
import { UnAuthForm } from "components/";
import { doRequestAndLocalLogin } from "utils/requests";
import { errors } from 'utils/modesErrors'

const modes = {
  INITIAL: { name: "INITIAL", url: "clients/v1/users" },
  INCORRECT_DATA: {
    name: "INCORRECT_DATA",
    errorCode: 4103,
    message: {
      color: "danger",
      translation: "login.errors.INCORRECT_DATA",
    },
  },
  SERVER_OFFLINE: errors.serverOffline,
  CLIENT_BLOCKED: errors.clientBlocked,
  EMAIL_MISSING: errors.mandatoryFieldMissing,
  PASSWORD_MISSING: errors.mandatoryFieldMissing,
  USER_INACTIVE: errors.userInactive
};

const Login = ({ t, login }) => {
  const [mode, setMode] = useState(modes.INITIAL);

  const onSubmit = (credentials) =>
    doRequestAndLocalLogin({
      credentials,
      localLogin: login,
      handleErrorsParams: { modes, setMode },
    });

  const resetMode = () => {
    if (mode !== modes.INITIAL) setMode(modes.INITIAL);
  };

  return (
    <UnAuthForm
      onSubmit={onSubmit}
      resetMode={resetMode}
      title={t("login.title")}
      submitText={t("general.signin")}
      secondaryText={t("general.doNotHaveAccountYet")}
      secondaryLink={t("general.registerHere")}
      secondaryTo={"/register"}
      fields={[
        {
          label: t("general.email"),
          placeholder: t("general.email"),
          type: "email",
          name: "email",
          id: "email",
        },
        {
          label: t("general.password"),
          placeholder: t("general.password"),
          type: "password",
          name: "password",
          id: "password",
        },
      ]}
      message={mode.message}
    >
      <span
        style={{
          margin: "35px auto",
          textAlign: "center",
          color: "#000",
          fontWeight: "bold",
        }}
      >
        <Link as={RouterLink} to="/recover-password" color="primary">
          ¿Has olvidado tu contraseña?
        </Link>
      </span>
    </UnAuthForm>
  );
};

export default withTranslation()(Login);
